import { CommonResponse } from '@/features/betApprovals/pages/BetApprovals/tabs/Services/BetApprovals.utils';
import betgalaxy from '@/assets/icons/bookies/betgalaxy_logo.png';
import betprofessor from '@/assets/icons/bookies/betprofessor_logo.png';
import betroyal from '@/assets/icons/bookies/betroyal_logo.png';
import fiestabet from '@/assets/icons/bookies/fiestabet_logo.png';
import goldenrush from '@/assets/icons/bookies/goldenrush_logo.png';
import juicybet from '@/assets/icons/bookies/juicybet_logo.png';
import junglebet from '@/assets/icons/bookies/junglebet_logo.png';
import puntcity from '@/assets/icons/bookies/puntcity_logo.png';
import puntgenie from '@/assets/icons/bookies/puntgenie_logo.png';
import questbet from '@/assets/icons/bookies/questbet_logo.png';
import sterlingparker from '@/assets/icons/bookies/sterlingparker_logo.png';
import vikingbet from '@/assets/icons/bookies/vikingbet_logo.png';
import volcanobet from '@/assets/icons/bookies/volcanobet_logo.png';
import wellbet from '@/assets/icons/bookies/wellbet_logo.png';
import slambet from '@/assets/icons/bookies/slambet_logo.png';
import golden888 from '@/assets/icons/bookies/golden888_logo.png';
import oldgill from '@/assets/icons/bookies/oldgill_logo.png';
import sugarcastle from '@/assets/icons/bookies/sugarcastle_logo.png';
import gigabet from '@/assets/icons/bookies/gigabet_logo.png';
import buffalobet from '@/assets/icons/bookies/buffalobet_logo.png';
import templebet from '@/assets/icons/bookies/templebet_logo.png';
import bet777 from '@/assets/icons/bookies/bet777_logo.png';
import betcloud from '@/assets/icons/bookies/betcloud_logo.png';
import chromabet from '@/assets/icons/bookies/chromabet_logo.png';
import titanbet from '@/assets/icons/bookies/titanbet_logo.png';
import bet575 from '@/assets/icons/bookies/bet575_logo.png';
import magnumbet from '@/assets/icons/bookies/magnumbet_logo.png';
import { EBetTypes } from './MarketTableItem';
import { isWincore } from '@/features/betApprovals/pages/BetApprovals/tabs/Approvals';

const POSITION_MAP: Record<string, string> = {
  Top1: 'Win',
  Top2: 'Top 2',
  Top3: 'Top 3',
  Top4: 'Top 4',
};

export function extractSGMValuesForMarketInfo(data: CommonResponse) {
  return {
    legs: data.betLegs.length,
    matchName: data.betLegs[0].event_data.match_name as string,
    timeStamp: data.betLegs[0].event_start as string,
    isBonusBet: data.isBonusBet,
    items: data.betLegs.map((leg) => ({
      propositionName: leg.event_data.proposition_name as string,
      marketName: leg.event_data.market_name as string,
    })),
  };
}

export function extractSRMValuesForMarketInfo(data: CommonResponse) {
  return {
    legs: data.betLegs.length,
    timeStamp: data.betLegs[0].event_start as string,
    isBonusBet: data.isBonusBet,
    venue: data.betLegs[0].event_data.venue_name as string,
    raceNumber: data.betLegs[0].event_data.race_number as string,
    items: data.betLegs.map((leg) => ({
      runnerNumber: leg.event_data.runner_number as number,
      runnerName: leg.event_data.runner_name as number,
      position: POSITION_MAP[leg.bet_description] ?? '',
    })),
  };
}

export function extractBlendedValuesForMarketInfo(data: CommonResponse) {
  return {
    legs: data.betLegs.length,
    timeStamp: data.betLegs[0].event_start as string,
    isBonusBet: data.isBonusBet,
    venue: data.betLegs[0].event_data.venue_name as string,
    raceNumber: data.betLegs[0].event_data.race_number as string,
    items: data.betLegs.map((leg) => ({
      runnerNumber: leg.event_data.runner_number as number,
      runnerName: leg.event_data.runner_name as string,
    })),
  };
}

export function extractSMultiValuesForMarketInfo(data: CommonResponse) {
  return {
    legs: data.betLegs.length,
    timeStamp: data.betLegs[0].event_start as string,
    isBonusBet: data.isBonusBet,
    priceType: data.priceType,
    items: data.betLegs.reduce((acc, cur) => {
      if (cur.event_type === 'Race') {
        acc.push({
          type: cur.event_type as string,
          runnerName: cur.event_data.runner_name as string,
          odds: cur.odds as number,
          marketName: cur.event_data.market_name as string,
          venueName: cur.event_data.venue_name as string,
          raceNumber: cur.event_data.race_number as number,
          priceType: cur.price_type as string,
        });
      }
      if (cur.event_type === 'Match') {
        acc.push({
          type: cur.event_type as string,
          matchName: cur.event_data.match_name as string,
          odds: cur.odds as number,
          propositionName: cur.event_data.proposition_name as string,
          marketName: cur.event_data.market_name as string,
        });
      }
      return acc;
    }, []),
  };
}

export function extractSingleRacingValuesForMarketInfo(data: CommonResponse) {
  const isTote =
    data.betLegs[0].price_type === 'tote_single_mid' ||
    data.betLegs[0].price_type === 'tote_single_best';

  const isMysteryBet = data.betLegs[0].price_type === 'mystery_bet';
  let toteBadge = '';
  let marketName = '';

  if (isTote) {
    marketName = data.betLegs[0].bet_description.includes('Place')
      ? 'Place TOTE'
      : 'Win TOTE';
    if (data.betLegs[0].price_type === 'tote_single_mid')
      toteBadge = 'TOTE SINGLE MID';
    else if (data.betLegs[0].price_type === 'tote_single_best')
      toteBadge = 'TOTE SINGLE BEST';
  }

  if (isMysteryBet) marketName = data.betLegs[0].bet_description;

  return {
    status: 'Open', // needs to be from the backend response
    venueName: data.betLegs[0].event_data.venue_name as string,
    timeStamp: data.betLegs[0].event_start as string,
    raceNumber: data.betLegs[0].event_data.race_number as number,
    mbl: data.mblStake,
    isBonusBet: data.isBonusBet,
    icon: data.betLegs[0].event_icon as string,
    price_type: data.betLegs[0].price_type as string,
    marketName:
      isTote || isMysteryBet
        ? marketName
        : (data.betLegs[0].event_data.market_name as string),
    runnerName: data.betLegs[0].event_data.runner_name as string,
    runnerNumber: data.betLegs[0].event_data.runner_number as number,
    toteBadge,
  };
}

export function extractSingleSportValuesForMarketInfo(data: CommonResponse) {
  return {
    status: 'Open', // needs to be from the backend response
    isBonusBet: data.isBonusBet,
    matchName: data.betLegs[0].event_data.match_name as string,
    timeStamp: data.betLegs[0].event_start as string,
    icon: data.betLegs[0].event_icon as string,
    propositionName: data.betLegs[0].event_data.proposition_name as string,
    marketName: data.betLegs[0].event_data.market_name as string,
  };
}

export function logoFromBookieName(bookieName: string) {
  let bookieNameStripped: string;
  if (window.BETCLOUD_ENV.NODE_ENV === 'production') {
    // production has the format of bookieName-punter
    bookieNameStripped = bookieName.split('-')[0].toLocaleLowerCase();
  } else {
    // every other env has the format of env-bookieName
    bookieNameStripped = bookieName.split('-')[1].toLowerCase();
  }

  switch (bookieNameStripped) {
    case 'betgalaxy':
      return betgalaxy;
    case 'betprofessor':
      return betprofessor;
    case 'betroyale':
      return betroyal;
    case 'fiestabet':
      return fiestabet;
    case 'goldenrush':
      return goldenrush;
    case 'juicybet':
      return juicybet;
    case 'junglebet':
      return junglebet;
    case 'puntcity':
      return puntcity;
    case 'puntgenie':
      return puntgenie;
    case 'questbet':
      return questbet;
    case 'sterlingparker':
      return sterlingparker;
    case 'vikingbet':
      return vikingbet;
    case 'volcanobet':
      return volcanobet;
    case 'wellbet':
      return wellbet;
    case 'slambet':
      return slambet;
    case 'golden888':
      return golden888;
    case 'oldgill':
      return oldgill;
    case 'sugarcastle':
      return sugarcastle;
    case 'gigabet':
      return gigabet;
    case 'buffalobet':
      return buffalobet;
    case 'templebet':
      return templebet;
    case 'bet777':
      return bet777;
    case 'chromabet':
      return chromabet;
    case 'titanbet':
      return titanbet;
    case 'bet575':
      return bet575;
    case 'magnumbet':
      return magnumbet;
    default:
      return betcloud;
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getEventNameFromBet(bet: any) {
  if (!bet) return 'N/A';

  let type = bet.type ?? bet.betType;
  const betLegs = bet.betLegs ?? bet.bet_legs;

  const isMysteryBet = betLegs.some((leg) => leg.price_type === 'mystery_bet');

  if (isMysteryBet) return 'Mystery Bet';

  if (type === 'Single') {
    type =
      (!bet.eventType || bet.eventType === ''
        ? bet.betLegs[0].event_type
        : bet.eventType) === 'Match'
        ? 'SINGLE_SPORT'
        : 'SINGLE_RACING';
  }

  switch (type) {
    case EBetTypes.SRM:

    case EBetTypes.SGM:
    case EBetTypes.MULTI: {
      return `${betLegs.length} Leg Multi`;
    }
    case EBetTypes.BLENDED: {
      return `${betLegs.length} Leg Blended`;
    }
    case EBetTypes.SINGLE_RACING: {
      if (!isWincore) return bet.eventTitle;
      return `${betLegs[0].event_data.venue_name} - R${betLegs[0].event_data.race_number}`;
    }
    case EBetTypes.SINGLE_SPORT: {
      if (!isWincore) return bet.eventTitle;
      return betLegs[0].event_data.match_name;
    }
    default: {
      return 'N/A';
    }
  }
}
